/* eslint-disable class-methods-use-this */
import axios from '@/plugins/axios'

class PlaygroundService {
  getPlaygroundUserView() {
    return axios.get('/v1/playground/camera')
  }
}

export default new PlaygroundService()
