<template>
  <div>
    <v-card>
      <template v-if="listCameras && listCameras.length > 0">
        <v-card-title>
          {{ $t('Playground') }}
        </v-card-title>
        <div
          v-for="(camera, index) in listCameras"
          :key="index"
        >
          <v-card class="mb-4">
            <v-card-text class="pb-4">
              <video
                :id="`video-${index}`"
                :ref="`video-${index}`"
                :src="camera"
                class="video"
                crossorigin
                autoplay
                playsinline
                controls
                muted
                :poster="require('@/assets/images/toppage/playground.jpg')"
              >
                <source
                  type="application/x-mpegURL"
                  :src="camera"
                />
              </video>
            </v-card-text>
          </v-card>
        </div>
      </template>
      <v-card
        v-else
        class="mt-5"
      >
        <v-img
          class="w-full"
          :src="require('@/assets/images/toppage/playground.jpg')"
          alt=""
          height="40vw"
        />
        <v-card-title class="align-start">
          <span class="font-weight-semibold">{{ $t('Playground') }}</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="align-start">
          <span>{{ $t('NoMembersInPlaygroundMsg') }}</span>
        </v-card-text>
      </v-card>
      <v-card-text>
        <v-btn
          outlined
          color="warning"
          block
          @click="$router.back()"
        >
          {{ $t('Back') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import PlaygroundService from '@/services/PLaygroundService'
import Plyr from 'plyr'
import Hls from 'hls.js'
import 'plyr/dist/plyr.css'
import Bus from '@/utils/bus'

export default {
  setup() {
    const listCameras = ref([])

    const initPlayer = () => {
      const players = {}
      Array.from(document.querySelectorAll('video')).forEach(video => {
        const source = video.getElementsByTagName('source')[0].src
        const defaultOptions = {
        }

        function updateQuality(newQuality) {
          if (newQuality === 0) {
            window.hls.currentLevel = -1 // Enable AUTO quality if option.value = 0
          } else {
            window.hls.levels.forEach((level, levelIndex) => {
              if (level.height === newQuality) {
                console.log(`Found quality match with ${newQuality}`)
                window.hls.currentLevel = levelIndex
              }
            })
          }
        }

        if (!Hls.isSupported()) {
          players[video.id] = new Plyr(video, defaultOptions)
        } else {
          const hls = new Hls()
          hls.loadSource(source)

          // From the m3u8 playlist, hls parses the manifest and returns
          // all available video qualities. This is important, in this approach,
          // we will have one source on the Plyr player.
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            // Transform available levels into an array of integers (height values).
            const availableQualities = hls.levels.map(l => l.height)
            availableQualities.unshift(0) // prepend 0 to quality array

            // Add new qualities to option
            defaultOptions.quality = {
              default: 0, // Default - AUTO
              options: availableQualities,
              forced: true,
              onChange: e => updateQuality(e),
            }

            // Add Auto Label
            defaultOptions.i18n = {
              captions: 'Phụ đề',
              speed: 'Tốc độ',
              normal: 'Bình thường',
              quality: 'Chất lượng',
              loop: 'Lặp lại',
              start: 'Bắt đầu',
              end: 'Kết thúc',
              all: 'Toàn bộ',
              reset: 'Đặt lại',
              disabled: 'Tắt',
              enabled: 'Bật',
              qualityLabel: {
                0: 'Tự động',
              },
            }

            hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
              const span = document.querySelector(
                ".plyr__menu__container [data-plyr='quality'][value='0'] span",
              )
              if (hls.autoLevelEnabled) {
                span.innerHTML = `Tự động (${hls.levels[data.level].height}p)`
              } else {
                span.innerHTML = 'Tự động'
              }
            })

            // Initialize new Plyr player with quality options
            players[video.id] = new Plyr(video, defaultOptions)
          })

          hls.attachMedia(video)
          window.hls = hls
        }
      })
    }

    const getUserCameras = async () => {
      try {
        const resp = await PlaygroundService.getPlaygroundUserView()

        const { data } = resp.data

        listCameras.value = data.surveillance_camera || []
        setTimeout(() => {
          initPlayer()
        }, 100)
      } catch {}
    }

    const handleMsg = async codeEvent => {
      if (codeEvent === 'play_ground_001') {
        await getUserCameras()
      }
    }

    onMounted(async () => {
      await getUserCameras()
      Bus.$on('haveMessage', handleMsg)
    })

    onUnmounted(() => {
      Bus.$off('haveMessage', handleMsg)
    })

    return {
      listCameras,
    }
  },
}
</script>
<style lang="scss" scoped>
.video {
  width: 100%;
  height: 20vh;
}
</style>
